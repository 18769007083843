<template>
<div class="container-fluid">
      <ConfirmAlert ref="MyConfirmAlert" :title="AlertTitle" :message="AlertMessage" @Sure="YesIamSure($event)" :sureResult="sureResult" />
         <SuccessAlert ref="MySuccessAlert" :title="AlertTitle" :message="AlertMessage" :back="false"/>

            <div class="col-md-12">
                <div class="col-lg-12 col-md-12 ">
                <div class="row">
                    <div class="col-md-4">
                    <div class="input-group no-border">
                        <input type="text" v-model="filter.name" class="form-control" placeholder="بحث">
                        <button @click="onSearch()" type="submit" class="btn btn-white btn-round btn-just-icon">
                            <i class="material-icons">search</i>
                            <div class="ripple-container"></div>
                        </button>
                    </div>
                    </div>
                    <div class="col-md-4">
                    <PerPage id="limit" title="كل صفحة"  Mykey="limit"  :MyValue="filter.limit" @Select="HandleSelect('limit',$event)"/>
                    </div>
                    <div class="col-md-4">
                        <router-link v-if="manageService('can_add')" :to="{name: 'admin.dashboard.product.category.create',params:{product_uuid:filter.product_uuid}}" class="btn btn-primary btn-md btn-add" > <i class="fas fa-pencil-alt"> </i>
                            اضافة تصنيف جديد للمنتج</router-link>
                    </div>
                
                </div>
                </div>
            </div>

            <div class="col-md-12">
              <div class="card">
                <div class="card-header card-header-primary" style="text-align: center;">
                  <h4 class="card-title ">قائمة تصنيفات المنتج</h4>
                </div>
                <div class="card-body" style="text-align:center;">
                  <div class="table-responsive">
                    <table class="table">
                      <thead class=" text-primary">
                        <th>
                          #
                        </th>
                        <th>
                          اسم التصنيف بالعربي
                        </th>
                        <th>
                          اسم التصنيف بالانجليزي
                        </th>
                        <th>
                          اسم التصنيف الفرعي بالعربي
                        </th>
                        <th>
                          اسم التصنيف الفرعي بالانجليزي
                        </th>
 
                        
                        <th v-if="manageService('can_edit') || manageService('can_delete')">
                          اجراءات
                        </th>

                      </thead>
                      <tbody>
                        <tr v-for="(category,index) in CategoriesList.data" :key="index">
                          <td>
                            {{index+1}}
                          </td>
                          <td>
                            {{category.category.name.ar}}
                          </td>
                          <td>
                            {{category.category.name.en}}
                          </td>
                          <td>
                            {{category.sub_category?category.sub_category.name.ar:'لايوجد'}}
                          </td>
                          <td>
                            {{category.sub_category?category.sub_category.name.en:'لايوجد'}}
                          </td>
 

                          <td class="text-primary" v-if="manageService('can_edit') || manageService('can_delete')">
                            <router-link v-if="manageService('application::view')" :to="{name: 'admin.dashboard.product.category.view', params: {product_uuid:category.product.uuid,id: category.id}}" class="btn btn-success btn-sm" > 
                             عرض</router-link>
                            <router-link v-if="manageService('can_edit')" :to="{name: 'admin.dashboard.product.category.edit', params: {product_uuid:category.product.uuid,id: category.id}}" class="btn btn-warning btn-sm" > 
                             تعديل</router-link>
                             <a href="" v-if="manageService('can_delete')" class="btn btn-danger btn-sm" @click.prevent="ShowConfirm(category)">
                             حذف</a> 
                          </td>
                        </tr>
                       </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
             <pagination v-if="CategoriesList.last_page > 1" :pagination="CategoriesList" :offset="5" @paginate="onPaginationChage"></pagination> 
             </div>
</div>
</template>

<script>
import store from '../../../../store/index'
import {mapState} from 'vuex';

import Pagination from '../../../../components/Pagination.vue'
import PerPage from '../../../../components/PerPage'
import ConfirmAlert from '../../../../components/ConfirmAlert'
import SuccessAlert from '../../../../components/SuccessAlert'
export default {
  data(){
   return{
   filter:{
        name:'',
        page:1,
        paginate:1,
        limit:'100',
        product_uuid:'',
    },  
     AlertTitle:'',
     AlertMessage:'',
     SelectedCategory:null,
     sureResult:true,
   }
  },
  computed:{
    ...mapState({
        CategoriesList:state=>state.admin.categories.ProductCategoriesList,
    }),
    manageService: (state) => (service) => {
            return store.getters['admin/canManageService'](service);
    },
    
   },
   methods:{
     HandleSelect(key,value){
         this.filter[key]=value;
         this.onPaginationChage(1);
     },
        onPaginationChage(page){
       this.filter.page=page;
         store.dispatch('admin/fetchProductCategoriesList',this.filter).then((response)=>{
         }).catch((error)=>{
         })
       },
        onSearch(){
          store.commit('admin/PleaseStartLoading');
                this.filter.page=1;
                store.dispatch('admin/fetchProductCategoriesList',this.filter).then(()=>{
                  store.commit('admin/PleaseStopLoading');
                }).catch((error)=>{
                  store.commit('admin/PleaseStopLoading');
                });  
        },  
        YesIamSure(value){
        if(value && this.sureResult){
            this.sureResult=false;
            this.onDelete();
        }
        },
        CancelAlert(){
        this.sureResult=false;
        },
        ShowConfirm(category){
                this.AlertTitle="معلومات",
                this.AlertMessage="هل انت متاكد من الحذف ؟";
                this.$refs.MyConfirmAlert.showModel();
                this.SelectedCategory=category;
        },
         onDelete(){
       //     store.commit('admin/PleaseStartLoading');
          store.dispatch('admin/deleteProductCategory',{product_uuid:this.filter.product_uuid,id:this.SelectedCategory.id}).then((Response)=>{
         //   store.commit('admin/PleaseStopLoading');
            this.sure=false;
           this.$refs.MyConfirmAlert.hideModel();
            this.AlertTitle="معلومات",
            this.AlertMessage="تمت الحذف بنجاح";
            this.$refs.MySuccessAlert.showModel();
            store.dispatch('admin/fetchProductCategoriesList',this.filter).then(()=>{
             this.sureResult=true;
            }).catch(error=>{

            })
          }).catch((error)=>{
         //store.commit('admin/PleaseStopLoading');
        });
        

    },
   },
      components:{
       Pagination,
       PerPage,
       ConfirmAlert,
       SuccessAlert
   },

  beforeRouteEnter(to,from,next){     
    let product_uuid=to.params.product_uuid;
      store.commit('admin/PleaseStartLoading');
      store.dispatch('admin/fetchProductCategoriesList',{product_uuid:product_uuid,page:1,name:'',paginate:1,limit:100}).then(()=>{
         store.commit('admin/PleaseStopLoading');
          next(vm=>{
            vm.filter.product_uuid=product_uuid;
          });  
      }).catch((error)=>{
         store.commit('admin/PleaseStopLoading');
       next(false);
      });             

    },
}
</script>

<style>

</style>